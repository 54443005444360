import axios from 'axios';
const CancelToken = axios.CancelToken;

const isDevelopment = process.env.NODE_ENV === 'development';

/**
 *
 * Example creating a REST Client with default headers
 * const client = Client({
 *	defaultHeaders: {  // Optional
 *		'x-custom1-header': 'value1',
 *		'x-custom2-header': '1value',
 *	},
 * })
 *
 * Example Performing request with optional values
 * const result = await client.get({
 * 		params: { foo: 'bar' }, // Optional
 * 		data: { time: Date.now() }, // Optional
 * 		headers: { 'x-newcustom-header': 123, 'x-custom1-header': 'value122' }, // Optional // Last header overrides defaul client header
 *		endpoint: `/page-data/foo/page-data.json`, // Required
 *	})
 */

const unpack = result => {
	return result.data;
};

// Factory function for a axios-based REST client
export default class Client {
	constructor(defaultConfig = {}, defaultData = {}) {
		this.defaultHeaders = defaultConfig.defaultHeaders || {};
		this.baseURL = defaultConfig.baseURL || null;
		this.defaultData = defaultData;
	}

	makeRequest = async ({ endpoint, method, params, data, headers = {}, cancel = {}, ...rest }) => {
		try {
			const url = this.baseURL ? `${this.baseURL}/${endpoint}` : endpoint;
			const result = await axios({
				...rest,
				method,
				headers: { ...this.defaultHeaders, ...headers },
				url,
				data: data ? data : null,
				params: params ? params : null,
				cancelToken: new CancelToken(cancelFn => {
					cancel.cancel = cancelFn;
				}),
			});
			const response = unpack(result);
			return response;
		} catch (error) {
			throw error;
		}
	};

	get = async config => {
		return await this.makeRequest({ method: 'get', ...config });
	};

	post = async ({ data = {}, ...rest }) => {
		return await this.makeRequest({
			method: 'post',
			data: { ...this.defaultData, ...data },
			...rest,
		});
	};

	put = async ({ data = {}, ...rest }) => {
		return await this.makeRequest({
			method: 'put',
			data: { ...this.defaultData, ...data },
			...rest,
		});
	};

	patch = async ({ data = {}, ...rest }) => {
		return await this.makeRequest({
			method: 'patch',
			data: { ...this.defaultData, ...data },
			...rest,
		});
	};

	del = async ({ data = {}, ...rest }) => {
		return await this.makeRequest({
			method: 'delete',
			data: { ...this.defaultData, ...data },
			...rest,
		});
	};

	remoteLogError = async (message, level = 'Error') => {
		const data = {
			level,
			message,
			component: 'Storefront',
		};

		try {
			if (isDevelopment) {
				console.log('PimCore log:', { endpoint: 'log/', data: data });
			}
			const endpoint = 'log/';
			const host = process.env.GATSBY_PIMCORE_BASE_URL;
			const version = process.env.GATSBY_PIMCORE_REST_VERSION;
			const baseUrl = `${host}/rest/${version}`;
			const url = `${baseUrl}/${endpoint}`;

			const result = await axios({
				method: 'post',
				headers: this.defaultHeaders,
				url,
				data,
			});
			const response = unpack(result);
			return response;
		} catch (error) {
			console.error('Could not log error to Pimcore.', error);
		}
	};
}
