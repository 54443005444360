import './ErrorModal.scss';
import React from 'react';
import Divider from '@mui/material/Divider';
import useTranslations from '@src/hooks/useTranslations';
import { Button } from '@mui/material';
import BEMHelper from 'react-bem-helper';
import Modal from '@src/components/Modal';

const bem = new BEMHelper('error-modal');

export default function ErrorModal({
	onClose = () => null,
	buttonLabel = undefined,
	title = undefined,
	titleKey = undefined,
	messageKey = undefined,
	children = undefined,
}) {
	const t = useTranslations();
	const label = buttonLabel ? buttonLabel : t('kinto.general.try-again');

	if (!children || children.length === 0) {
		if (messageKey) {
			children = t(messageKey);
		} else {
			children = t('kinto.error-modal.general');
		}
	}
	const titleValue = title ?? titleKey ? t(titleKey) : t('kinto.error-modal.title');

	return (
		<Modal open={true} handleClose={onClose} xButton bemClass={'blue'}>
			<div {...bem('')}>
				<div {...bem('info')}>
					<h2 {...bem('title')}>{titleValue}</h2>
					{Array.isArray(children) ? (
						children.map((item, i) => {
							return item &&
								item.startsWith &&
								(item.startsWith('apigw') || item.startsWith('kinto.error.')) ? (
								<p key={i}>{t(item)}</p>
							) : (
								<p key={i}>{item}</p>
							);
						})
					) : children.startsWith('apigw') ? (
						<p>{t(children)}</p>
					) : (
						<p>{children}</p>
					)}
				</div>
				<div {...bem('button')}>
					<Button
						className="ButtonClose"
						aria-label={label}
						variant="contained"
						color="secondary"
						onClick={onClose}
						fullWidth
					>
						{label}
					</Button>
				</div>
				<div {...bem('contact')}>
					<b {...bem('contact', 'title')}>{t('kinto.error-modal.contact')}</b>
					<div {...bem('contact', 'line-no-margin')}>
						<p>{t('kinto.error-modal.call-us')}</p>
					</div>
					<div {...bem('contact', 'line')}>
						<h2>{t('kinto.error-modal.phonenumber')}</h2>
					</div>
					<Divider />
				</div>
				<div {...bem('button')}>
					<Button
						className="ButtonEmail"
						aria-label={label}
						variant="contained"
						color="secondary"
						href={'mailto:' + t('kinto.error-modal.mail')}
						to={'mailto:' + t('kinto.error-modal.mail')}
						fullWidth
					>
						{t('kinto.error-modal.mail-us')}
					</Button>
				</div>
			</div>
		</Modal>
	);
}
