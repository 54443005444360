import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import LeasingEntity from '@src/models/LeasingEntity.model';
import * as _ from 'lodash';
import { RootState } from '../store';

const leasingEntityAdapter = createEntityAdapter({
	selectId: (leasingEntity: LeasingEntity) => leasingEntity.id,
});

export const leasingEntitySlice = createSlice({
	name: 'leasingEntity',
	initialState: leasingEntityAdapter.getInitialState(),
	reducers: {
		//Ridecell leasing entities
		setLeasingEntities: (state, action) => {
			leasingEntityAdapter.setAll(state, action.payload);
		},
		setLeasingEntityStations: (state, action) => {
			leasingEntityAdapter.updateOne(state, {
				id: action.payload.id,
				changes: { stations: action.payload.stations },
			});
		},
	},
});

export const { setLeasingEntities, setLeasingEntityStations } = leasingEntitySlice.actions;
const leasingEntitySelectors = leasingEntityAdapter.getSelectors<RootState>(state => state.leasingEntity);

export const allLeasingEntities = leasingEntitySelectors.selectAll;
export default leasingEntitySlice.reducer;
