import React, { useEffect, useState } from 'react';
import Link from '@src/components/Link';
import shareLogo from '@src/assets/images/Kinto.png';
import flexLogo from '@src/assets/images/Kinto-flex.png';
import mainLogo from '@src/assets/images/Kinto_Blue_RGB.png';
import { useConnect } from 'redux-bundler-hook';
import { SITE_TYPES } from '@src/bundles/site';
import { localizedUrl, checkIsLandingPage } from '@src/utils/navigate';

const Logo = ({ location }) => {
	const { language, service, siteType } = useConnect('selectLanguage', 'selectService', 'selectSiteType');
	const [logoUrl, setLogoUrl] = useState('');
	const [logoImg, setLogoImg] = useState(mainLogo);
	useEffect(() => {
		const isLandingPage = checkIsLandingPage(location, language);

		var logoImgVal = isLandingPage ? mainLogo : service === SITE_TYPES.SHARE ? shareLogo : flexLogo;
		setLogoUrl(localizedUrl(siteType == SITE_TYPES.BUSINESS ? '/foretag' : '/', language, service));
		setLogoImg(logoImgVal);
	}, [location, service, siteType]);

	return (
		<Link to={logoUrl} tabIndex="0" aria-label="Logo">
			<img src={logoImg} alt="Kintos" />
		</Link>
	);
};

export default Logo;
