import { get, TOKEN_KEY } from '@src/utils/localStorage';
import { getCookie } from '@src/utils/cookieUtil';
import { setCookie } from '@src/utils/cookieUtil';
import { del, put } from '@src/utils/localStorage';

let token;
export const loadToken = () => {
	if (token) {
		return token;
	}
	const localStorageToken = get(TOKEN_KEY);
	if (localStorageToken) {
		return localStorageToken;
	}
	const tokenCookie = getCookie(TOKEN_KEY);
	if (tokenCookie && tokenCookie !== '') {
		return tokenCookie;
	}
	return null;
};

export const setTokenValue = tokenValue => {
	token = tokenValue;
};

export const setTokenValueInStorage = (tokenValue, rememberToken) => {
	if (rememberToken) {
		put(TOKEN_KEY, tokenValue);
		setCookie(TOKEN_KEY, '');
	} else {
		del(TOKEN_KEY);
		setCookie(TOKEN_KEY, tokenValue);
	}
};
