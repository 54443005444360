import Modal from '@src/components/Modal';
import React, { useState } from 'react';
import useSetting from '@src/hooks/useSetting';
import Link from '@src/components/Link';
import { useConnect } from 'redux-bundler-hook';
import useTranslations from '@src/hooks/useTranslations';
import { SITE_TYPES } from '@src/bundles/site';
import BEMHelper from 'react-bem-helper';
import { getServiceFromUrlNew, localizedUrl } from '@src/utils/navigate';
import { useAppDispatch } from '@src/app/hooks';
import { setLanguage } from '@src/app/features/language.slice';
import { ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { navigate } from 'gatsby';

const bem = new BEMHelper('header');

export const Languages = ({ languageArray, location }) => {
	const t = useTranslations();
	let service = getServiceFromUrlNew(location.pathname);
	const { doSetLanguage, language, siteType } = useConnect('doSetLanguage', 'selectLanguage', 'selectSiteType');
	const dispatch = useAppDispatch();
	return (
		languageArray.length > 1 && (
			<>
				<Select
					{...bem('link', 'type-desktop', 'language')}
					variant="standard"
					inputProps={{
						IconComponent: () => null,
					}}
					disableUnderline={true}
					IconComponent={() => null}
					onChange={(event, selected) => {
						doSetLanguage(selected.props.value);
						dispatch(setLanguage(selected.props.value));
						navigate(
							localizedUrl(
								siteType == SITE_TYPES.BUSINESS ? '/foretag' : '/',
								selected.props.value,
								service
							)
						);
					}}
					renderValue={value => (
						<p {...bem('link', 'type-desktop', 'language active')}>
							{value.toUpperCase()}
							{' - '}
							{t('kinto.menu.change-language')}
						</p>
					)}
					defaultValue={language}
					value={language}
				>
					{languageArray.map(item => (
						<MenuItem key={item} value={item}>
							{t(`kinto.choose-language.${item}`)}
						</MenuItem>
					))}
				</Select>
			</>
		)
	);
};
