import { formatIncompletePhoneNumber, AsYouType } from 'libphonenumber-js';

export const transformPhonenumber = (phoneNumber = '', countryCode) => {
	const asYouType = new AsYouType(countryCode);
	phoneNumber = formatIncompletePhoneNumber(phoneNumber, countryCode);

	asYouType.input(phoneNumber);
	const numberValue = asYouType.getNumberValue();

	if (!numberValue) {
		return phoneNumber;
	}
	return numberValue;
};

export const transformDateString = dateString => {
	const dateParts = dateString.split(/[.-]+/);
	const date =
		dateParts[0].length === 4 ? new Date(dateString) : new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

	if (isNaN(date.getTime())) {
		return dateString;
	}

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const parsedDate =
		dateParts[0].length === 2 ? `${pad(day)}.${pad(month)}.${pad(year)}` : `${pad(year)}.${pad(month)}.${pad(day)}`;

	return parsedDate;
};

export const transformDateStringRidecell = expDate => {
	const dateParts = expDate.split(/[.-]+/);
	const date = dateParts[0].length === 4 ? new Date(expDate) : new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const parsedDate = `${pad(year)}-${pad(month)}-${pad(day)}`;

	return parsedDate;
};

function pad(num) {
	return num < 10 ? `0` + num : num;
}

export const transformSsn = _ssn => {
	if (!_ssn || _ssn === '') {
		return '';
	}
	let ssn = _ssn;
	const delimiterIndex = ssn.indexOf('-');
	if (delimiterIndex <= 0) {
		ssn = `${ssn.slice(0, ssn.length - 4)}-${ssn.slice(-4)}`;
	}
	if (ssn.length < 13) {
		let year = parseInt('20' + ssn.slice(0, 2));
		const now = new Date();
		ssn = `${now.getFullYear() - year < 0 ? '19' : '20'}${ssn}`;
	}
	return ssn;
};
