import { createSelector } from 'redux-bundler';
import * as _ from 'lodash';
import VehicleType from '@src/models/VehicleType.model';

export class VehicleTypeState {
	//Initial structure is based on Pimcore vehicle type, extended with Ridecell data
	vehicleTypes: Array<{
		id: '';
		VehicleType: '';
		Brand: '';
		AddtionalInformation: '';
		Model: '';
		NoOfSeats: '';
		RatePerHour: '';
		RatePerKilometer: '';
		RatePerHourBusiness: '';
		RatePerKilometerBusiness: '';
		FlexPrice: '';
		FlexPriceBusiness: '';
		FlexPriceExtra: '';
		RidecellID: '';
		svFlexBookingDocumentPath: '';
		enFlexBookingDocumentPath: '';
		ModalDocumentLink: {
			path: '';
		};
		Images: {
			image: {
				filename: '';
				fullpath: '';
				srcset: {
					descriptor: '';
					url: '';
					resolutions: {
						url: '';
						resolution: '';
					};
				};
			};
		};
		Size: '';
		Towbar: '';
		MainImage: {
			fullpath: '';
		};
		//TODO: create model structure
		ridecellVehicleType: {};
		//TODO: create model structure
		ridecellStation: {};
		//TODO: create model structure
		ridecellLeasingPlans: [];
	}>;
	//TODO: Create VehicleType model!
	ridecellVehicleTypes: Array<{}>;
}

const VehicleTypes = {
	name: 'vehicleTypes',
	getReducer: () => {
		const initialState: VehicleTypeState = {
			vehicleTypes: [],
			ridecellVehicleTypes: [], //TODO: Refactor, temp for functionality testing
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_VEHICLE_TYPES':
					//TODO: refactor this (Upsert functionality) Not
					return { ...state, vehicleTypes: payload };
				case 'SET_RIDECELL_VEHICLE_DATA':
					return { ...state, ridecellVehicleTypes: payload };
				default:
					return state;
			}
		};
	},
	doInitializeVehicleTypes:
		vehicleTypes =>
		({ dispatch }) => {
			dispatch({ type: 'SET_VEHICLE_TYPES', payload: vehicleTypes });
		},
	doInitializeRidecellVehicleTypes:
		vehicleTypes =>
		({ dispatch }) => {
			dispatch({ type: 'SET_RIDECELL_VEHICLE_DATA', payload: vehicleTypes });
		},
	selectAllPimcoreVehicleTypes: state => state.vehicleTypes.vehicleTypes,
	selectAllRidecellVehicleTypes: state => state.vehicleTypes.ridecellVehicleTypes,
	//Combines data from Pimcore and Ridecell to single VehicleObject
	selectAllVehicleTypesOld: state => {
		//TODO: Remove this if no need for ridecell vehicle types!
		let pimcoreVehicleMap = _.keyBy(state.vehicleTypes.vehicleTypes, 'RidecellID');
		let allVehicleTypes = state.vehicleTypes.ridecellVehicleTypes.map(item => {
			return { ...pimcoreVehicleMap[item.id], ...item };
		});
		return allVehicleTypes.map(vt => new VehicleType(vt));
	},
	selectAllVehicleTypes: state => {
		//TODO: Remove this if no need for ridecell vehicle types!
		return state.vehicleTypes.vehicleTypes.map(vt => new VehicleType(vt));
	},
	selectVehicleTypesWithLeasingEntities: createSelector(
		'selectAllVehicleTypes',
		'selectAllVehicleLeasingEntities',
		(allVehicleTypes, allVehicleLeasingEntities) => {
			let groupedLE = _.groupBy(allVehicleLeasingEntities, item => item.vehicle_type.id);
			_.forEach(allVehicleTypes, vt => {
				vt.leasingEntities = groupedLE[vt.RidecellID];
			});
			return _.filter(allVehicleTypes, vt => !!vt.leasingEntities).map(vt => new VehicleType(vt));
		}
	),
	selectVehicleTypesWithValidLeasingEntities: createSelector(
		'selectVehicleTypesWithLeasingEntities',
		allVehicleTypesWithLeasingEntities => {
			//TODO: Filter out expired leasing entities if any
			//Filter out leasing entities without station
			return allVehicleTypesWithLeasingEntities.filter(
				vt => vt.leasingEntities && vt.leasingEntities.findIndex(item => !!item.stations?.length) != -1
			);
		}
	),
};

export default VehicleTypes;
