import './BlockedUserInfoModal.scss';
import React from 'react';
import Link from '@src/components/Link';
import useSetting from '@src/hooks/useSetting';
import BEMHelper from 'react-bem-helper';
import useTranslations from '@src/hooks/useTranslations';
import Button from '@mui/material/Button';
import image from '@src/assets/images/Kinto_missing_information.jpg';
import Modal from '@src/components/Modal';

const bem = new BEMHelper('blocked-user-info');

function BlockedUserInfoModal({ open, handleClose, blockedTitle, blockedText }) {
	const t = useTranslations();
	const getSetting = useSetting();
	const policyLink = getSetting('BlockedUserModal_PolicyLink', '');

	return (
		<Modal open={open} handleClose={handleClose} bemClass={'small'}>
			<div {...bem('')}>
				<div {...bem('image')}>
					<img src={image} alt="" />
				</div>
				<div {...bem('title')}>
					<h1 className="h4">{blockedTitle}</h1>
				</div>
				<p {...bem('text')}>{blockedText}</p>
				<p className="privacy-policy-container">
					<Link key={'policy-link'} to={policyLink} onClick={() => handleClose()}>
						{t('kinto.checkout.insurance.agree.link')}
					</Link>
				</p>
				<Button
					aria-label={t('kinto.general.close')}
					variant="contained"
					color="primary"
					fullWidth
					onClick={() => handleClose()}
				>
					{t('kinto.general.close')}
				</Button>
			</div>
		</Modal>
	);
}

export default BlockedUserInfoModal;
