import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { loadToken } from '@src/utils/tokenUtils';
import { store } from '../store';

const languagesLookup = {
	da: 'da-DK',
	en: 'en-US',
	sv: 'sv-SE',
};

export const kintoApiSlice = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.GATSBY_AZURE_API_GATEWAY_BASE_URL,
		prepareHeaders: (headers, api) => {
			headers.set('Ocp-Apim-Subscription-Key', process.env.GATSBY_ONBOARDING_API_KEY);
			const token = loadToken();
			if (token) {
				headers.set('Telematics-AuthToken', 'JWT ' + token);
			}
			const selectedLanguage = store.getState().language.selected;
			headers.set('Accept-Language', languagesLookup[selectedLanguage]);
		},
	}),
	endpoints: () => ({}),
});
