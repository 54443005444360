import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import VehicleType from '@src/models/VehicleType.model';
import LeasingEntity from '@src/models/LeasingEntity.model';
import * as _ from 'lodash';
import { RootState } from '../store';

const vehicleTypeAdapter = createEntityAdapter({
	selectId: (vehicleType: VehicleType) => vehicleType.RidecellID,
});

export const vehicleSlice = createSlice({
	name: 'vehicleType',
	initialState: vehicleTypeAdapter.getInitialState(),
	reducers: {
		// Pimcore vehicle types
		setVehicleTypes: (state, action) => {
			vehicleTypeAdapter.setMany(state, action.payload);
		},
	},
});
export const selectVehicleTypesWithLeasingEntities = (state: RootState) => {
	let groupedLE = _.groupBy(_.cloneDeep(state.leasingEntity.entities), item => item.vehicle_type.id);
	let vehicleTypes = _.cloneDeep(state.vehicleType.entities);
	if (groupedLE) {
		_.forEach(vehicleTypes, vt => {
			vt.leasingEntities = groupedLE[vt.RidecellID]?.map(le => new LeasingEntity(le));
		});
	}
	return _.filter(vehicleTypes, vt => !!vt.leasingEntities).map(vt => new VehicleType(vt));
};

export const { setVehicleTypes } = vehicleSlice.actions;

export default vehicleSlice.reducer;
