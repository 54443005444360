// extracted by mini-css-extract-plugin
export var breakpointLg = "1366px";
export var breakpointMd = "1024px";
export var breakpointSm = "768px";
export var breakpointXl = "1680px";
export var breakpointXs = "576px";
export var colorBackground = "#f7f9f9";
export var colorBackgroundDark = "#e3e9eb";
export var colorBlack = "#2c353b";
export var colorBorder = "#e3e9eb";
export var colorError = "#d83a13";
export var colorInfo = "#327ea4";
export var colorPrimary = "#00708d";
export var colorPrimaryDark = "#01647d";
export var colorPrimaryLight = "#389fb5";
export var colorPrimaryXDark = "#015165";
export var colorPrimaryXLight = "#baefed";
export var colorSuccess = "#05874c";
export var colorTertiary = "#ffa62b";
export var colorTertiaryDark = "#ed9a26";
export var colorTextDisabled = "#a8b7ba";
export var colorWhite = "#fff";
export var textReset = "none";