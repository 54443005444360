import { SITE_TYPES } from '@src/bundles/site';

const specialUrls = ['bli-medlem', 'kom-igang', 'mitt-kinto', 'bli-medlem/', 'kom-igang/', 'mitt-kinto/'];
const absoluteURLPattern = /^https?:\/\//;

export const localizedUrl = (url, language, service) => {
	const isSpecialUrl = specialUrls.some(x => url.split('?')[0].endsWith(x));
	const defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE || 'sv';
	const marketPrefix = process.env.GATSBY_PATH_PREFIX;
	let marketUrlPrefix = '/';
	if (marketPrefix && marketPrefix !== '') {
		marketUrlPrefix = `/${marketPrefix}/`;
	}
	if (alreadyLocalized(url, language, defaultLanguage, service, marketUrlPrefix)) {
		return url;
	}
	const urlSansSlash = url.replace(/^\/+/g, ''); // remove leading slash
	if (!language || language === defaultLanguage) {
		if (isSpecialUrl) {
			return `${marketUrlPrefix}${urlSansSlash}`;
		}
		if (service) {
			return urlSansSlash === ''
				? `${marketUrlPrefix}kinto-${service}`
				: `${marketUrlPrefix}kinto-${service}/${urlSansSlash}`;
		}
		return `${marketUrlPrefix}${urlSansSlash}`;
	} else if (language !== defaultLanguage) {
		if (isSpecialUrl) {
			return urlSansSlash === ''
				? `${marketUrlPrefix}${language}`
				: `${marketUrlPrefix}${language}/${urlSansSlash}`;
		}
		if (service) {
			return urlSansSlash === ''
				? `${marketUrlPrefix}${language}/kinto-${service}`
				: `${marketUrlPrefix}${language}/kinto-${service}/${urlSansSlash}`;
		}
		return urlSansSlash === '' ? `${marketUrlPrefix}${language}` : `${marketUrlPrefix}${language}/${urlSansSlash}`;
	}
};

export const checkIsLandingPage = (location, language) => {
	const marketPrefix = process.env.GATSBY_PATH_PREFIX;
	const isLandingPage =
		location &&
		(location.pathname === `${marketPrefix}` ||
			location.pathname === `${marketPrefix}/` ||
			location.pathname === `${marketPrefix}/${language}` ||
			location.pathname === `${marketPrefix}/${language}/` ||
			location.pathname === `${marketPrefix}/kom-igang` ||
			location.pathname === `${marketPrefix}/${language}/kom-igang` ||
			location.pathname === `${marketPrefix}/kom-igang/` ||
			location.pathname === `${marketPrefix}/${language}/kom-igang/` ||
			location.pathname === `${marketPrefix}/mitt-kinto` ||
			location.pathname === `${marketPrefix}/${language}/mitt-kinto` ||
			location.pathname === `${marketPrefix}/mitt-kinto/` ||
			location.pathname === `${marketPrefix}/${language}/mitt-kinto/`);
	return isLandingPage;
};

const alreadyLocalized = (url, language, defaultLanguage, service, marketUrlPrefix) => {
	if (language === defaultLanguage) {
		return url.indexOf(`${marketUrlPrefix}kinto-${service}`) !== -1;
	}
	return url.indexOf(`${marketUrlPrefix}${language}/kinto-${service}`) !== -1;
};
// Implemented this as temporary solution for SSR
export const getLanguageFromUrlNew = path => {
	let langFromUrl = process.env.GATSBY_DEFAULT_LANGUAGE;
	const langArrayString = process.env.GATSBY_LANGUAGES;
	const langArray = langArrayString ? langArrayString.split(',') : [];
	langArray.forEach(lang => {
		if (path.startsWith(`/${lang}`)) {
			langFromUrl = lang;
		}
	});
	return langFromUrl;
};
export const getLanguageFromUrl = langArray => {
	let langFromUrl = undefined;
	if (typeof window !== 'undefined' && window.location) {
		langArray.forEach(lang => {
			if (window.location.pathname.startsWith(`/${lang}`)) {
				langFromUrl = lang;
			}
		});
	}
	return langFromUrl;
};

// Refactoring getServiceFromUrl, can use this one until there is a better way to handle services
export const getServiceFromUrlNew = path => {
	if (path) {
		const shareRegex = new RegExp('^(/[a-z][a-z])*/kinto-share/');
		const flexRegex = new RegExp('^(/[a-z][a-z])*/kinto-flex/');
		const joinRegex = new RegExp('^(/[a-z][a-z])*/kinto-join/');

		if (shareRegex.test(path)) {
			return SITE_TYPES.SHARE;
		} else if (flexRegex.test(path)) {
			return SITE_TYPES.FLEX;
		} else if (joinRegex.test(path)) {
			return SITE_TYPES.JOIN;
		}
	} else {
		return undefined;
	}
};

export const getServiceFromUrl = langArray => {
	let serviceFromUrl = undefined;
	if (typeof window !== 'undefined' && window.location) {
		if (window.location.pathname.startsWith(`/kinto-${SITE_TYPES.SHARE}`)) {
			serviceFromUrl = SITE_TYPES.SHARE;
		}
		if (window.location.pathname.startsWith(`/kinto-${SITE_TYPES.FLEX}`)) {
			serviceFromUrl = SITE_TYPES.FLEX;
		}
		if (window.location.pathname.startsWith(`/kinto-${SITE_TYPES.JOIN}`)) {
			serviceFromUrl = SITE_TYPES.JOIN;
		}
		langArray.forEach(lang => {
			if (window.location.pathname.startsWith(`/${lang}/kinto-${SITE_TYPES.SHARE}`)) {
				serviceFromUrl = SITE_TYPES.SHARE;
			}
			if (window.location.pathname.startsWith(`/${lang}/kinto-${SITE_TYPES.FLEX}`)) {
				serviceFromUrl = SITE_TYPES.FLEX;
			}
			if (window.location.pathname.startsWith(`/${lang}/kinto-${SITE_TYPES.JOIN}`)) {
				serviceFromUrl = SITE_TYPES.JOIN;
			}
		});
	}
	return serviceFromUrl;
};

export const getSiteTypeFromUrl = (marketUrlPrefix, langArray) => {
	let siteType = SITE_TYPES.PRIVATE;
	if (typeof window !== 'undefined' && window.location) {
		const marketPrefix = marketUrlPrefix ? `/${marketUrlPrefix}` : '';
		const pathname = window.location.pathname;
		langArray.forEach(lang => {
			if (
				pathname.startsWith(`${marketPrefix}/${lang}/kinto-${SITE_TYPES.SHARE}/foretag`) ||
				pathname.startsWith(`${marketPrefix}/${lang}/kinto-${SITE_TYPES.FLEX}/foretag`) ||
				pathname.startsWith(`${marketPrefix}/kinto-${SITE_TYPES.SHARE}/foretag`) ||
				pathname.startsWith(`${marketPrefix}/kinto-${SITE_TYPES.FLEX}/foretag`)
			) {
				siteType = SITE_TYPES.BUSINESS;
			}
		});
	}
	return siteType;
};

export const fixSpecialUrl = url => {
	const isSpecialUrl = specialUrls.some(x => url.endsWith(x));
	if (isSpecialUrl) {
		const marketPrefix = process.env.GATSBY_PATH_PREFIX;
		let marketUrlPrefix = '/';
		if (marketPrefix && marketPrefix !== '') {
			marketUrlPrefix = `/${marketPrefix}/`;
		}
		const urlSansSlash = url.replace(/^\/+/g, ''); // remove leading slash
		return `${marketUrlPrefix}${urlSansSlash}`;
	} else {
		return url;
	}
};

export const getHomePageForLanguage = (defaultLanguage, language, homePageConfig) => {
	let homePage = '/';
	if (homePageConfig) {
		homePage = `/${homePageConfig}`;
	}
	if (defaultLanguage === language) {
		return homePage;
	} else if (homePage === '/') {
		return `/${language}`;
	} else {
		return `/${language}${homePage}`;
	}
};

export const isAbsoluteURL = url => {
	return absoluteURLPattern.test(url);
};
