import { SITE_TYPES, ONBOARDING_TYPES } from '@src/bundles/site';
import { CHECKOUT_NAME_TAG, CONTACT_FORMS_TAG, REGISTER_NAME_TAG } from './tagConstants';

const push = data =>
	new Promise((resolve, reject) => {
		if (!data) {
			reject('No data to push');
		}

		if (window['google_tag_manager'] && typeof window.dataLayer !== 'undefined') {
			if (process.env.GATSBY_GOOGLE_SERVICE_LOG === 'true') {
				console.info('Pushing to Google Analytics: ', data);
			}
			resolve(window.dataLayer.push(data));
		} else {
			if (data.eventCallback) {
				data.eventCallback();
			}
			reject('GTM not installed');
		}
	});

const pushBookingEvent = async (
	country,
	bookingSiteType,
	checkoutStep,
	conversion,
	productName,
	carModelName,
	price,
	currencyCode
) => {
	const product = {
		category: 'services',
		subCategory: 'mobility',
		productInfo: {
			productName: productName,
			brand: 'kinto',
		},
	};
	if (carModelName) {
		product.productInfo.modelName = carModelName;
	}
	if (price) {
		product.productInfo.price = price;
	}
	if (currencyCode) {
		product.productInfo.currencyCode = currencyCode;
	}

	const checkoutName =
		bookingSiteType === SITE_TYPES.PRIVATE ? CHECKOUT_NAME_TAG.private : CHECKOUT_NAME_TAG.bussiness;
	const eventName = conversion !== 1 ? 'checkout' : 'purchase';

	const data = {
		event: eventName,
		page: {
			category: 'online-subscription',
			name: 'subscription-checkout',
			country: country,
		},
		checkout: {
			name: checkoutName,
			step: checkoutStep,
			conversion: conversion,
		},
		product: [product],
	};

	return push(data);
};

const pushOnboardingEvent = async (country, onboardingType, registerStep, conversion) => {
	const onboardingName =
		onboardingType === ONBOARDING_TYPES.PRIVATE ? REGISTER_NAME_TAG.private : REGISTER_NAME_TAG.bussiness;
	const data = {
		event: 'workflowevent',
		page: {
			category: 'register',
			name: 'register-flow',
			country: country,
		},
		workflow: {
			name: onboardingName,
			step: registerStep,
			conversion: conversion,
		},
	};

	return push(data);
};

const pushFormSubmitedEvent = async (locationPath, urlNewDevelopment, urlFormB2B) => {
	if (!locationPath || !urlNewDevelopment || !urlFormB2B) {
		return;
	}
	if (locationPath === urlNewDevelopment) {
		const data = {
			event: 'formevent',
			eventcategory: CONTACT_FORMS_TAG.newDevelopment,
			eventaction: 'form-step',
			eventlabel: 'form-success-share',
		};
		return push(data);
	} else if (locationPath === urlFormB2B) {
		const data = {
			event: 'formevent',
			eventcategory: CONTACT_FORMS_TAG.b2b,
			eventaction: 'form-step',
			eventlabel: 'form-success-share',
		};
		return push(data);
	}
};

const pushLoginPageEvent = async () => {
	const data = {
		event: 'loginevent',
		eventcategory: 'login',
		eventaction: 'login-form',
	};

	return push(data);
};

const pushSuccessfulLoginEvent = async () => {
	const data = {
		event: 'loginevent',
		eventcategory: 'login',
		eventaction: 'login-success',
	};

	return push(data);
};

export const googleAnalytics = {
	pushBookingEvent,
	pushOnboardingEvent,
	pushFormSubmitedEvent,
	pushLoginPageEvent,
	pushSuccessfulLoginEvent,
};
