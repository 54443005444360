import React from 'react';
import { transformPhonenumber } from '@src/form/transformation';
import { TextField } from '@mui/material';
import useTranslations from '@src/hooks/useTranslations';

export default function Phonenumber({ values, touched, errors, handleChange, handleBlur }) {
	const t = useTranslations();
	const country = process.env.GATSBY_MARKET;
	return (
		<TextField
			label={t('kinto.form.phonenumber')}
			id={t('kinto.form.phonenumber')}
			variant="outlined"
			name="phonenumber"
			type="tel"
			required
			onChange={event => {
				const parsedNumber = transformPhonenumber(event.target.value, country.toUpperCase());
				event.target.value = parsedNumber;
				handleChange(event);
			}}
			value={values.phonenumber}
			onBlur={handleBlur}
			error={errors.phonenumber && touched.phonenumber}
			helperText={errors.phonenumber && touched.phonenumber && errors.phonenumber}
		/>
	);
}
