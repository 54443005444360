import * as dkcpr from 'danish-ssn';

export function diffMinutes(dt2, dt1) {
	if (!dt1 || !dt2) {
		return 0;
	}
	var diff = (dt2.getTime() - dt1.getTime()) / 1000;
	diff /= 60;
	return Math.abs(Math.round(diff));
}

export function createFlexMonthlyPriceString(fee) {
	if (!fee) {
		return fee;
	}
	const resolvedOptions = new Intl.NumberFormat('se-SV', {
		style: 'currency',
		currency: 'SEK',
	}).resolvedOptions();
	const currencyOptions = {
		minimumFractionDigits: resolvedOptions.minimumFractionDigits,
		maximumFractionDigits: resolvedOptions.maximumFractionDigits,
	};
	const formattedFee = fee.toLocaleString('se-SV', currencyOptions);
	return `${formattedFee} kr/mån`;
}

export function getMonthsDiff(d1, d2) {
	if (!d1 || !d2) {
		return;
	}
	const date1 = new Date(d1);
	const date2 = new Date(d2);
	let months;
	months = (date2.getFullYear() - date1.getFullYear()) * 12;
	months -= date1.getMonth() + 1;
	months += date2.getMonth();
	return months <= 0 ? 0 : months;
}

export function isUnder20YearsOfAgeByDanishSsn(personalNumberString) {
	try {
		var personalNumber = dkcpr(personalNumberString);
		if (personalNumber.valid) {
			const now = new Date();
			return now.getFullYear() - personalNumber.date.getFullYear() < 20;
		}
		return false;
	} catch (error) {
		return false;
	}
}
export const isDateWithinDays = (date, days) => {
	const today = new Date();
	const futureDate = new Date(today);
	futureDate.setDate(today.getDate() + days);

	return date <= futureDate;
};

export const parseDate = value => {
	if (!value) {
		return null;
	}
	const dateParts = value.split(/[.-]/);
	const year = dateParts[2];
	const month = dateParts[1];
	const day = dateParts[0];

	if (year && month && day) {
		return new Date(`${year}-${month}-${day}`);
	} else {
		return null;
	}
};
