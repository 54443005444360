import './MissingInformationModal.scss';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { navigate } from 'gatsby';
import useTranslations from '@src/hooks/useTranslations';
import { Button } from '@mui/material';
import image from '@src/assets/images/Kinto_missing_information.jpg';
import Modal from '@src/components/Modal';
import { useConnect } from 'redux-bundler-hook';
import { localizedUrl } from '@src/utils/navigate';

const bem = new BEMHelper('missing-information');

function MissingInformationModal({ open, handleClose }) {
	const t = useTranslations();
	const { customerInfo, language, service, doSetError } = useConnect(
		'selectCustomerInfo',
		'selectLanguage',
		'selectService',
		'doSetError'
	);

	const handleClickFinishRegistration = () => {
		//TODO: Validate this functionality after change!
		let isDriverLicenseExpired = false;
		if (customerInfo.drivingLicense?.license_expiry_date) {
			isDriverLicenseExpired = new Date(customerInfo.drivingLicense?.license_expiry_date) < new Date();
		}
		if (isDriverLicenseExpired) {
			//If driver license expired only error is shown, there is a plan to make this to re-verify automatically later on.
			doSetError({
				title: t('kinto.register.drivers-license-expired-title'),
				message: t('kinto.register.drivers-license-expired'),
			});
		} else {
			navigate(localizedUrl('bli-medlem', language, service), {
				replace: true,
			});
		}
	};

	return (
		<Modal open={open} handleClose={handleClose} bemClass={'small'}>
			<div {...bem('')}>
				<div {...bem('image')}>
					<img src={image} alt="" />
				</div>
				<div {...bem('title')}>
					<h1 className="h4">{t('kinto.my-page.finish-profile-title')}</h1>
				</div>
				<p {...bem('text')}>{t('kinto.my-page.finish-profile-text')}</p>
				<Button
					aria-label={t('kinto.my-page.finish-registration')}
					variant="contained"
					color="primary"
					fullWidth
					onClick={() => {
						handleClickFinishRegistration();
						handleClose();
					}}
				>
					{t('kinto.my-page.finish-registration')}
				</Button>
				<Button
					aria-label={t('kinto.general.later')}
					variant="outlined"
					color="primary"
					fullWidth
					onClick={() => handleClose()}
				>
					{t('kinto.general.later')}
				</Button>
			</div>
		</Modal>
	);
}

export default MissingInformationModal;

export function isOnboardingComplete(customerInfo) {
	return (
		customerInfo?.drivingLicense?.is_license_verified ||
		customerInfo?.onboardingStatus?.driving_license_status === 'complete'
	);
}
