import React from 'react';
import getStore from './src/bundles';
import { ReduxBundlerProvider } from 'redux-bundler-hook';
import { ThemeProvider, createTheme } from '@mui/material';
import { themeCustom } from './src/layouts/theme';
import Layout from './src/layouts/layout';
import { SITE_TYPES } from './src/bundles/site';
import { Provider } from 'react-redux';
import { store as reduxStore } from './src/app/store';

const state = {};

const store = getStore(state);
const theme = createTheme(themeCustom);

export const onClientEntry = async () => {
	/* For testing and mocking with msw uncomment the following.
	   commented for now because webpack adds the module in production bundle.
	*/
	// if (process.env.ENABLE_MOCKING) {
	// 	const msw = require('./__tests__/msw-worker');
	// 	await msw.mswWorker.start();
	// }
};

export const wrapPageElement = ({ element, props }) => {
	return (
		<ThemeProvider theme={theme}>
			<Layout {...props}>{element}</Layout>
		</ThemeProvider>
	);
};

export const wrapRootElement = ({ element }) => {
	return (
		<Provider store={reduxStore}>
			<ReduxBundlerProvider store={store}>{element}</ReduxBundlerProvider>
		</Provider>
	);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
	const state = store.getState();
	if (location.pathname === state.search.previousPathname) {
		store.dispatch({ type: 'RESTORE_PREVIOUS_QUERY' });
	}
	if (prevLocation && location.pathname !== '/sok' && prevLocation.pathname === '/sok') {
		store.dispatch({ type: 'SET_QUERY', payload: '' });
	}

	// Set correct service based on url path
	if (
		location.pathname.startsWith('/kinto-flex') ||
		location.pathname.startsWith(`${state.site.market}/kinto-flex`) ||
		location.pathname.startsWith(`${state.site.language}/kinto-flex`) ||
		location.pathname.startsWith(`${state.site.market}/${state.site.language}/kinto-flex`)
	) {
		store.dispatch({ type: 'RESET_SELECTED_STATION', payload: null });
		store.dispatch({ type: 'SET_SERVICE', payload: SITE_TYPES.FLEX });
	} else if (
		location.pathname.startsWith('/kinto-share') ||
		location.pathname.startsWith(`${state.site.market}/kinto-share`) ||
		location.pathname.startsWith(`${state.site.language}/kinto-share`) ||
		location.pathname.startsWith(`${state.site.market}/${state.site.language}/kinto-share`)
	) {
		store.dispatch({ type: 'RESET_SELECTED_STATION', payload: null });
		store.dispatch({ type: 'SET_SERVICE', payload: SITE_TYPES.SHARE });
	}
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	// Set scroll restoration to manual to control scroll behavior during navigation
	if ('scrollRestoration' in window.history) {
		window.history.scrollRestoration = 'manual';
	}
	// If there's a hash in the URL, return false to prevent scrolling to the top of the page
	if (location.hash) {
		return false;
	}
	const currentPosition = getSavedScrollPosition(location, location.key);

	// If the currentPosition is not available, it means we are navigating back, so scroll to the top.
	if (!currentPosition) {
		window.scrollTo(0, 0);
	} else {
		// Scroll to the currentPosition with a slight delay using requestAnimationFrame for smoother scrolling
		window.setTimeout(() => {
			window.requestAnimationFrame(() => {
				window.scrollTo(...currentPosition);
			});
		}, 0);
	}
	return false;
};

// TODO: Waiting for design approval before implementing
// export const onServiceWorkerUpdateFound = () => {
// 	const answer = window.confirm(
// 		`Site has been updated since your last visit. Please press "OK" for update to the latest version`
// 	);

// 	if (answer === true) {
// 		window.location.reload();
// 	}
// };
