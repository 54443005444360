import Station from './Station.model';

export default class LeasingEntity {
	id: string;
	name: string;
	stations: Array<Station> = [];
	commitment_duration: number;
	vehicle_type: any;
	cities: Array<{ id: number; name: string }>;
	plan: {
		billing_cycle_frequency: number;
		billing_cycle_unit: string;
		display_name: string;
		fee: number;
		id: number;
		is_deleted: boolean;
		market: number;
		payment_provider_plan_id: string;
		billing_cycle_commitment: number;
		is_recurrent: boolean;
	};

	constructor(vehicleType: Partial<LeasingEntity>) {
		Object.assign(this, vehicleType);
	}
}
