import { useEffect } from 'react';
import { useConnect } from 'redux-bundler-hook';
import { fetchCustomerInfoApiCall, isInvalidSession } from '@src/custom-api/kinto-api/client';

let fetchTimeoutHandle = undefined;

/**
 * Hook for fetching Customer Info.
 */
function useCustomerInfo() {
	const { doSetCustomerInfo, customerInfo, doLogoutCustomer, doSetError, market } = useConnect(
		'doSetCustomerInfo',
		'selectCustomerInfo',
		'doLogoutCustomer',
		'doSetError',
		'selectMarket'
	);

	useEffect(() => {
		if (!customerInfo.token || customerInfo.token === '' || customerInfo.customerId !== '') {
			return;
		}

		(async () => {
			if (fetchTimeoutHandle) {
				clearTimeout(fetchTimeoutHandle);
			}
			// Avoid making too many API calls
			fetchTimeoutHandle = setTimeout(async () => {
				try {
					if (!customerInfo.token || customerInfo.token === '' || customerInfo.customerId !== '') {
						return;
					}
					const newCustomerInfo = await fetchCustomerInfoApiCall(market);
					doSetCustomerInfo(newCustomerInfo);
				} catch (error) {
					if (isInvalidSession(error)) {
						doSetError({
							titleKey: 'kinto.auth.invalid-user-session-title',
							messageKey: 'kinto.auth.invalid-user-session-message',
							redirectOnClose: true,
						});
						doLogoutCustomer();
					}
				}
			}, 30);
		})();
	}, [doSetCustomerInfo, customerInfo]);

	return customerInfo;
}

export default useCustomerInfo;
