import { diffMinutes } from '@src/utils/dateUtils';

export default {
	name: 'onboarding',
	getReducer: () => {
		const initialState = {
			onfidoSdkToken: undefined,
			onfidoSdkTokenTimestamp: undefined,
			onfidoData: undefined,
			onfidoApplicantId: undefined,
			nemIdSsn: undefined,
			skipNemId: undefined,
			nemIdTransactionId: undefined,
			nemIdSuccess: undefined,
			nemIdIsOnAnotherDevice: undefined,
			mailingAddress: undefined,
			payslipsCaseNumber: undefined,
			isDLUnderMarketLimit: undefined,
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_ONFIDO_SDK_TOKEN':
					return {
						...state,
						onfidoSdkToken: payload,
						onfidoSdkTokenTimestamp: new Date(),
					};
				case 'SET_ONFIDO_WORKFLOW_RUN_ID':
					return {
						...state,
						workflowRunId: payload,
					};
				case 'CLEAR_ONFIDO_SDK_TOKEN':
					return {
						...state,
						onfidoSdkToken: undefined,
						onfidoSdkTokenTimestamp: undefined,
					};
				case 'SET_ONFIDO_DATA':
					return { ...state, onfidoData: payload };
				case 'SET_ONFIDO_APPLICANT_ID':
					return { ...state, onfidoApplicantId: payload };
				case 'SET_NEMID_SSN':
					return { ...state, nemIdSsn: payload };
				case 'SET_SKIP_NEMID': {
					return { ...state, skipNemId: payload };
				}
				case 'SET_NEMID_TRANSACTION_ID': {
					return { ...state, nemIdTransactionId: payload };
				}
				case 'SET_NEMID_SUCCESS': {
					return { ...state, nemIdSuccess: payload };
				}
				case 'SET_NEMID_ON_OTHER_DEVICE': {
					return { ...state, nemIdIsOnAnotherDevice: payload };
				}
				case 'SET_MAILING_ADDRESS': {
					return { ...state, mailingAddress: payload };
				}
				case 'SET_PAYSLIPS_CASE_NUMBER': {
					return { ...state, payslipsCaseNumber: payload };
				}
				case 'SET_IS_DL_UNDER_MARKET_LIMIT': {
					return { ...state, isDLUnderMarketLimit: payload };
				}
				default:
					return state;
			}
		};
	},
	doSetOnfidoSdkToken:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ONFIDO_SDK_TOKEN', payload: type });
		},
	doSetOnfidoWorkflowRunId:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ONFIDO_WORKFLOW_RUN_ID', payload: type });
		},
	doClearOnfidoSdkToken:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'CLEAR_ONFIDO_SDK_TOKEN' });
		},
	selectOnfidoSdkToken: state => state.onboarding.onfidoSdkToken,
	selectOnfidoWorkflowRunId: state => state.onboarding.workflowRunId,
	selectOnfidoSdkTokenIsExpired: state => diffMinutes(state.onboarding.onfidoSdkTokenTimestamp, new Date()) > 60,
	doSetOnfidoData:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ONFIDO_DATA', payload: type });
		},
	selectOnfidoData: state => state.onboarding.onfidoData,
	doSetOnfidoApplicantId:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ONFIDO_APPLICANT_ID', payload: type });
		},
	selectOnfidoApplicantId: state => state.onboarding.onfidoApplicantId,
	doSetNemIdSsn:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_NEMID_SSN', payload: type });
		},
	selectNemIdSsn: state => state.onboarding.nemIdSsn,
	doSetSkipNemId:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_SKIP_NEMID', payload: type });
		},
	selectSkipNemId: state => state.onboarding.skipNemId,
	doSetNemIdTransactionId:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_NEMID_TRANSACTION_ID', payload: type });
		},
	selectNemIdTransactionId: state => state.onboarding.nemIdTransactionId,
	doSetNemIdSuccess:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_NEMID_SUCCESS', payload: type });
		},
	selectNemIdSuccess: state => state.onboarding.nemIdSuccess,
	doSetNemIdIsOnAnotherDevice:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_NEMID_ON_OTHER_DEVICE', payload: type });
		},
	selectNemIdIsOnAnotherDevice: state => state.onboarding.nemIdIsOnAnotherDevice,
	doSetMailingAddress:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_MAILING_ADDRESS', payload: type });
		},
	selectMailingAddress: state => state.onboarding.mailingAddress,
	doSetPayslipsCaseNumber:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_PAYSLIPS_CASE_NUMBER', payload: type });
		},
	selectPayslipsCaseNumber: state => state.onboarding.payslipsCaseNumber,
	selectIsDLUnderMarketLimit: state => state.onboarding.isDLUnderMarketLimit,
};
