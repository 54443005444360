import React from 'react';
import useSetting from '@src/hooks/useSetting';
import ChatSalesforce from './ChatSalesforce';
import ChatImbox from './ChatImbox';

export default function Chat({ embeddable, isStationsPage, siteType }) {
	const getSetting = useSetting();
	const chatType = getSetting('chat_type'); // salesforce, imbox, none (null)

	return chatType === 'salesforce' ? (
		!embeddable && !isStationsPage && <ChatSalesforce siteType={siteType} />
	) : chatType === 'imbox' ? (
		!embeddable && !isStationsPage && <ChatImbox />
	) : (
		<></>
	);
}
