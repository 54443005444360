import './Modal.scss';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import useTranslations from '@src/hooks/useTranslations';
import { Container, Modal, IconButton } from '@mui/material';
import Close from '@src/assets/icons/cancel.inline.svg';

const bem = new BEMHelper('modal');

const ModalContainer = ({
	children,
	open,
	handleClose,
	xButton,
	xButtonAbsolute,
	bemClass,
	noMargin,
	modalStyle = {},
	...rest
}) => {
	const t = useTranslations();

	const toggle = () => {
		handleClose();
	};

	return (
		<Modal onClose={toggle} open={open} {...rest}>
			<div {...(bemClass ? { ...bem('', bemClass) } : { ...bem('') })} style={modalStyle}>
				<Container>
					{xButtonAbsolute && (
						<div {...bem('close', 'absolute')}>
							<IconButton color="primary" onClick={toggle}>
								<Close />
							</IconButton>
						</div>
					)}
					{xButton && (
						<div {...bem('close')}>
							<button aria-label={t('kinto.general.close')} tabIndex={0} onClick={toggle}>
								<Close fontSize="large" />
							</button>
						</div>
					)}
					{noMargin ? <div {...bem('no-margin')}>{children}</div> : <>{children}</>}
				</Container>
			</div>
		</Modal>
	);
};

export default ModalContainer;
