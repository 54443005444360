//User slice state, replacing old customer state
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import VehicleType from '@src/models/VehicleType.model';
import LeasingEntity from '@src/models/LeasingEntity.model';
import * as _ from 'lodash';
import { RootState } from '../store';

interface UserState {
	token: string;
}
const initialState = {
	token: null,
} satisfies UserState as UserState;
export const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
		setAuthenticationToken: (state, action) => {
			state.token = action.payload;
		},
	},
	selectors: {
		isAuthenticate: userState => !!userState.token,
	},
});
export const { setAuthenticationToken } = userSlice.actions;
export const { isAuthenticate } = userSlice.selectors;

export default userSlice.reducer;
