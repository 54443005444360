import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const languageSlice = createSlice({
	name: 'language',
	initialState: {
		selected: process.env.GATSBY_DEFAULT_LANGUAGE || 'sv',
	},
	reducers: {
		setLanguage: (state, action) => {
			state.selected = action.payload;
		},
	},
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
