import * as _ from 'lodash';

export class StationState {
	//TODO: Create stations model and replace any!
	stations: { [key: string]: any } = {};
}

//TODO: Created state but not in use currently, remove if not needed!
const Station = {
	name: 'station',
	getReducer: () => {
		const initialState: StationState = {
			stations: [],
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_STATIONS':
					_.forEach(payload, station => {
						state.stations[station.id] = station;
					});
					return state;
				case 'SET_STATION':
					state.stations[payload.id] = payload;
					return state;
				default:
					return state;
			}
		};
	},
	doSetStation:
		(station: any) =>
		({ dispatch }) => {
			dispatch({ type: 'SET_STATION', payload: station });
		},
	doSetStations:
		(stations: Array<any>) =>
		({ dispatch }) => {
			dispatch({ type: 'SET_STATIONS', payload: stations });
		},
	selectAllStations: state => Object.values(state.station.stations),
};

export default Station;
