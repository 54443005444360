const search = {
	name: 'search',
	getReducer: () => {
		const initialState = {
			query: '',
			previousQuery: '',
			previousPathname: '',
			active: false,
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_QUERY':
					return { ...state, query: payload };
				case 'SET_ACTIVE':
					return { ...state, active: payload };
				case 'SET_PREVIOUS_QUERY':
					return {
						...state,
						previousPathname: payload,
						previousQuery: state.query,
						query: initialState.query,
						active: false,
					};
				case 'CLEAR_PREVIOUS_QUERY':
					return state.active
						? {
								...state,
								previousPathname: initialState.previousPathname,
								previousQuery: initialState.previousQuery,
								query: initialState.query,
								active: false,
						  }
						: { ...state };
				case 'RESTORE_PREVIOUS_QUERY':
					return {
						...state,
						previousPathname: initialState.previousPathname,
						previousQuery: initialState.previousQuery,
						query: state.previousQuery,
						active: true,
					};
				default:
					return state;
			}
		};
	},
	doSetQuery:
		query =>
		({ dispatch }) => {
			dispatch({ type: 'SET_QUERY', payload: query });
		},
	doSetPreviousQuery:
		previousPathname =>
		({ dispatch }) => {
			dispatch({ type: 'SET_PREVIOUS_QUERY', payload: previousPathname });
		},
	doRestorePreviousQuery:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESTORE_PREVIOUS_QUERY' });
		},
	doSetActive:
		active =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ACTIVE', payload: active });
		},
	selectQuery: state => state.search.query,
	selectActive: state => state.search.active,
	selectPreviousPathname: state => state.search.previousPathname,
};

export default search;
