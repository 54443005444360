import { composeBundles, createCacheBundle } from 'redux-bundler';
import { saveState } from '@src/utils/localStorage';
import booking from './booking';
import customer from './customer';
import search from './search';
import registration from './registration';
import vehicleTypes from './vehicleTypes';
import site from './site';
import onboarding from './onboarding';
import Station from './stations';
// TODO: We should look into the possiblity of persisting seperate properties of a given
// state as there own keys in localStorage. For example we only really need cart.token
const cacheBundle = createCacheBundle({
	cacheFn: saveState,
});

export default composeBundles(
	booking,
	customer,
	search,
	registration,
	vehicleTypes,
	site,
	cacheBundle,
	onboarding,
	Station //TODO: remove if not in use!
);
