import { configureStore } from '@reduxjs/toolkit';
import { kintoApiSlice } from './services/kinto.api';
import vehicleStateReducer from './features/vehicle.slice';
import leasingEntityStateReducer from './features/leasingEntity.slice';
import userStateReducer from './features/user.slice';
import languageReducer from './features/language.slice';

export const store = configureStore({
	reducer: {
		vehicleType: vehicleStateReducer,
		leasingEntity: leasingEntityStateReducer,
		user: userStateReducer,
		language: languageReducer,
		[kintoApiSlice.reducerPath]: kintoApiSlice.reducer,
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware().concat(kintoApiSlice.middleware);
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
