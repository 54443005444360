import './PasswordField.scss';
import React, { useState } from 'react';
import useTranslations from '@src/hooks/useTranslations';
import { TextField, InputAdornment } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const handleMouseDownPassword = event => {
	event.preventDefault();
};

export default function Password({ values, touched, errors, handleChange, handleBlur }) {
	const t = useTranslations();

	const [showPasswordType, setShowPasswordType] = useState(true);
	return (
		<TextField
			label={t('kinto.form.password')}
			data-testid="password-field"
			id={t('kinto.form.password')}
			variant="outlined"
			type={showPasswordType === true ? 'password' : 'text'}
			name="password"
			value={values.password}
			required
			onChange={handleChange}
			onBlur={handleBlur}
			error={errors.password && touched.password}
			helperText={errors.password && touched.password && errors.password}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Tooltip
							arrow={true}
							placement={'right-start'}
							title={showPasswordType ? t('kinto.form.password-show') : t('kinto.form.password-hide')}
						>
							<IconButton
								id="password-button"
								aria-label="toggle password visibility"
								onClick={() => setShowPasswordType(!showPasswordType)}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPasswordType ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
}
