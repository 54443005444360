import './Header.scss';
import React, { useState, useEffect } from 'react';
import BEMHelper from 'react-bem-helper';
import useSetting from '@src/hooks/useSetting';
import Logo from '@src/components/Logo';
import { graphql, useStaticQuery } from 'gatsby';
import Close from '@src/assets/icons/cancel.inline.svg';
import Link from '@src/components/Link';
import { navigate } from 'gatsby';
import { useConnect } from 'redux-bundler-hook';
import Location from '@src/assets/icons/geolocation.inline.svg';
import User from '@src/assets/icons/users.inline.svg';
import Menu from '@src/assets/icons/menu.inline.svg';
import useTranslations from '@src/hooks/useTranslations';
import LoginModal from '@src/components/LoginModal';
import { Button } from '@mui/material';
import { googleAnalytics } from '@src/custom-api/googleanalytics/googleAnalytics';
import MissingInformationModal from '@src/components/account/MissingInformationModal';
import { SITE_TYPES } from '@src/bundles/site';
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events';
import useCustomerInfo from '@src/hooks/useCustomerInfo';
import { NavigateBefore } from '@mui/icons-material';
import { localizedUrl, checkIsLandingPage, getServiceFromUrlNew } from '@src/utils/navigate';
import Chat from '@src/components/Chat';
import BookingModal from '@src/components/booking/BookingModal';
import { ProductsMenu } from '@src/components/layout/header/ProductsMenu';
import { TypeLinks } from '@src/components/layout/header/TypeLinks';
import { Languages } from '@src/components/layout/header/Languages';
import ExclamationMark from '@src/assets/icons/exclamation-mark-inside-a-circle.inline.svg';
import ErrorModal from '@src/components/ErrorModal';
import MainMenu from '@src/components/layout/header/MainMenu';
import BlockedUserInfoModal from '@src/components/account/BlockedUserInfoModal';
import { getHomePageForLanguage } from '@src/utils/navigate';
import { setLanguage } from '@src/app/features/language.slice';
import { useAppDispatch } from '@src/app/hooks';

const bem = new BEMHelper('header');
const getHeaderQuery = graphql`
	query getPrivateNavigationLinks {
		allPrivateNavigationLink {
			edges {
				node {
					id
					label
					url
					language
					nestedLinks {
						label
						url
						language
					}
				}
			}
		}
		allPrivateSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allBusinessNavigationLink {
			edges {
				node {
					id
					label
					url
					language
					nestedLinks {
						label
						url
						language
					}
				}
			}
		}
		allBusinessSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexBusinessNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
		allFlexBusinessSecondaryNavigationLink {
			edges {
				node {
					id
					label
					url
					language
				}
			}
		}
	}
`;

let callbackFn = () => null;
let closeCallbackFn;

const Header = ({ location }) => {
	const t = useTranslations();
	const getSetting = useSetting();
	const customerInfo = useCustomerInfo();
	const embeddable = location && location.pathname.endsWith('-embeddable');
	const isStationsPage = location && location.pathname.indexOf('boka-bil') >= 0;
	const [showModal, setShowModal] = useState(false);
	const [languageMobileOpen, setLanguageMobileOpen] = useState(false);
	const [showRedirectInfoText, setShowRedirectInfoText] = useState(false);
	const [showTimeOutText, setShowTimeOutText] = useState(false);
	const [hideRegistration, setHideRegistration] = useState(false);
	const [returnToBookingAfterRegistration, setReturnToBookingAfterRegistration] = useState(false);
	const [showBookingModal, setShowBookingModal] = useState(false);
	const [navigationLinks, setNavigationLinks] = useState();
	const [navigationLinksSecond, setNavigationLinksSecond] = useState();
	const {
		isCustomerLoggedIn,
		showMissingInformation,
		doSetShowMissingInformation,
		siteType,
		doSetSiteType,
		isMissingInformation,
		isMissingInformationBusiness,
		doSetBookingSiteType,
		doSetLanguage,
		language,
		businessCustomer,
		doSetService,
		doResetBookingInfo,
		bookingInfo,
		doSetMembership,
		market,
		showAccountBlockedInformation,
		showAccountNotAllowedInformation,
		doSetShowAccountBlockedInformation,
		doLogoutCustomer,
		doRefreshCustomerInfo,
		error,
		doSetError,
	} = useConnect(
		'selectIsCustomerLoggedIn',
		'selectShowMissingInformation',
		'doSetShowMissingInformation',
		'selectSiteType',
		'doSetSiteType',
		'selectIsMissingInformation',
		'selectIsMissingInformationBusiness',
		'doSetBookingSiteType',
		'doSetLanguage',
		'selectLanguage',
		'selectBusinessCustomer',
		'doSetService',
		'doResetBookingInfo',
		'selectBookingInfo',
		'doSetMembership',
		'selectMarket',
		'selectShowAccountBlockedInformation',
		'selectShowAccountNotAllowedInformation',
		'doSetShowAccountBlockedInformation',
		'doLogoutCustomer',
		'doRefreshCustomerInfo',
		'selectError',
		'doSetError'
	);
	const service = getServiceFromUrlNew(location.pathname);
	const languageArray = getSetting('languages', null).split(',');
	const flexBookLink = getSetting('flexBookCarLink', null);
	const flexBusinessBookLink = getSetting('flexBusinessBookCarLink', null);
	const warningBannerText = getSetting('warningBannerText', null);
	const showFlexBusinessModal = getSetting('showFlexBusinessModal', null);
	const salesforceLink = getSetting('salesforce_mypages');
	const homePage = getSetting('home_page');

	const isLandingPage = checkIsLandingPage(location, language);

	const dispatch = useAppDispatch();
	useEffect(() => {
		const fetchData = async () => {
			if (customerInfo.customerId !== '') {
				await doSetMembership();
			}
			if (customerInfo.token && !customerInfo.customerId) {
				await doRefreshCustomerInfo();
			}
		};

		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerInfo.customerId, customerInfo.token]);

	useEffect(() => {
		if (isCustomerLoggedIn && businessCustomer && siteType === SITE_TYPES.BUSINESS) {
			doSetBookingSiteType(SITE_TYPES.BUSINESS);
		} else {
			doSetBookingSiteType(SITE_TYPES.PRIVATE);
		}
	}, [siteType, businessCustomer]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		if (
			businessCustomer &&
			isMissingInformationBusiness === true &&
			!location.pathname.endsWith('/kom-igang') &&
			!location.pathname.endsWith('/kom-igang/') &&
			!urlParams.has('SAMLRequest')
		) {
			doSetShowMissingInformation(true);
		} else if (
			!businessCustomer &&
			isMissingInformation === true &&
			!location.pathname.endsWith('/kom-igang') &&
			!location.pathname.endsWith('/kom-igang/') &&
			!urlParams.has('SAMLRequest')
		) {
			doSetShowMissingInformation(true);
		}
	}, [isMissingInformation, isMissingInformationBusiness, doSetShowMissingInformation]);

	const headerQuery = useStaticQuery(getHeaderQuery);

	const landingLinks = headerQuery.allPrivateNavigationLink.edges
		.map(privateNavigationLink => privateNavigationLink.node)
		.filter(
			pLink =>
				pLink.url.endsWith(`/kinto-${SITE_TYPES.SHARE}`) ||
				pLink.url.endsWith(`/kinto-${SITE_TYPES.FLEX}`) ||
				pLink.url.endsWith(`/kinto-${SITE_TYPES.JOIN}`) ||
				pLink.url === 'https://www.kinto-join.dk' // TODO: Remove hardcoded link when KINTO Join product is introduced
		);

	const privateNavigationLinks = headerQuery.allPrivateNavigationLink.edges
		.map(privateNavigationLink => privateNavigationLink.node)
		.filter(
			pLink =>
				!pLink.url.endsWith(`/kinto-${SITE_TYPES.SHARE}`) &&
				!pLink.url.endsWith(`/kinto-${SITE_TYPES.FLEX}`) &&
				!(pLink.url === 'https://www.kinto-join.dk')
		);
	const privateNavigationLinksSecond = headerQuery.allPrivateSecondaryNavigationLink.edges.map(
		privateNavigationLink => privateNavigationLink.node
	);
	const businessNavigationLinks = headerQuery.allBusinessNavigationLink.edges.map(
		businessNavigationLink => businessNavigationLink.node
	);
	const businessNavigationLinksSecond = headerQuery.allBusinessSecondaryNavigationLink.edges.map(
		businessNavigationLink => businessNavigationLink.node
	);
	const flexNavigationLinks = headerQuery.allFlexNavigationLink.edges.map(
		flexNavigationLink => flexNavigationLink.node
	);
	const flexNavigationLinksSecond = headerQuery.allFlexSecondaryNavigationLink.edges.map(
		flexNavigationLink => flexNavigationLink.node
	);
	const flexBusinessNavigationLinks = headerQuery.allFlexBusinessNavigationLink.edges.map(
		flexNavigationLink => flexNavigationLink.node
	);
	const flexBusinessNavigationLinksSecond = headerQuery.allFlexBusinessSecondaryNavigationLink.edges.map(
		flexNavigationLink => flexNavigationLink.node
	);

	useEffect(() => {
		if (isLandingPage) {
			setNavigationLinks([]);
			setNavigationLinksSecond(privateNavigationLinksSecond);
		} else if (service === SITE_TYPES.FLEX && siteType === SITE_TYPES.BUSINESS) {
			setNavigationLinks(flexBusinessNavigationLinks);
			setNavigationLinksSecond(flexBusinessNavigationLinksSecond);
		} else if (service === SITE_TYPES.FLEX) {
			setNavigationLinks(flexNavigationLinks);
			setNavigationLinksSecond(flexNavigationLinksSecond);
		} else if (siteType === SITE_TYPES.PRIVATE) {
			setNavigationLinks(privateNavigationLinks);
			setNavigationLinksSecond(privateNavigationLinksSecond);
		} else if (siteType === SITE_TYPES.BUSINESS) {
			setNavigationLinks(businessNavigationLinks);
			setNavigationLinksSecond(businessNavigationLinksSecond);
		}
	}, [siteType, service, location.pathname]);

	const handleMyPagesClick = () => {
		if (isCustomerLoggedIn && !salesforceLink) {
			const lLink = localizedUrl('/mitt-kinto', language, service);
			navigate(lLink);
		} else if (isCustomerLoggedIn && !!salesforceLink) {
			window.location.replace(salesforceLink);
		} else {
			googleAnalytics.pushLoginPageEvent();
			setShowModal(true);
		}
	};

	const handleBookingClick = () => {
		if (service === SITE_TYPES.FLEX) {
			doResetBookingInfo();
			if (siteType === SITE_TYPES.PRIVATE) {
				navigate(localizedUrl(flexBookLink, language, service));
			} else {
				navigate(localizedUrl(flexBusinessBookLink, language, service));
			}
		} else {
			navigate(localizedUrl('/boka-bil', language, service));
		}
	};

	const [menuExpanded, setMenuExpanded] = useState(false);

	const onMenuItemClick = () => {
		setMenuExpanded(false);
	};

	const toggleMenu = ({ force } = {}) => {
		setMenuExpanded(force === false || force === true ? force : !menuExpanded);
	};

	const showModalWrapper = ({
		hideRegistration = false,
		callback = () => null,
		closeCallback,
		showRedirectInfoText = false,
		returnToBookingAfterRegistration = false,
		showTimeOutText = false,
	} = {}) => {
		callbackFn = callback;
		closeCallbackFn = closeCallback;
		setHideRegistration(hideRegistration);
		setShowModal(true);
		setShowRedirectInfoText(showRedirectInfoText);
		setReturnToBookingAfterRegistration(returnToBookingAfterRegistration);
		setShowTimeOutText(showTimeOutText);
	};
	const defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE || 'sv';
	useEffect(() => {
		if (
			location.pathname.startsWith(`/${market}/kinto-${service}/foretag`) ||
			(language !== defaultLanguage &&
				location.pathname.startsWith(`/${market}/${language}/kinto-${service}/foretag`))
		) {
			doSetSiteType(SITE_TYPES.BUSINESS);
		}

		events.on(EVENT_SHOW_LOGIN_MODAL, showModalWrapper);
		return () => events.removeListener(EVENT_SHOW_LOGIN_MODAL, showModalWrapper);
	}, [language]);

	useEffect(() => {
		if (
			service === SITE_TYPES.FLEX &&
			bookingInfo.selectedCar === null &&
			(location.pathname.endsWith('/boka-bil') || location.pathname.endsWith('/boka-bil/'))
		) {
			if (siteType === SITE_TYPES.PRIVATE) {
				navigate(localizedUrl(flexBookLink, language, service));
			} else {
				navigate(localizedUrl(flexBusinessBookLink, language, service));
			}
		}
	}, [service, bookingInfo.selectedCar]);

	useEffect(() => {
		if (
			isCustomerLoggedIn &&
			businessCustomer &&
			!isMissingInformationBusiness &&
			isMissingInformationBusiness !== null &&
			localStorage.getItem('hide-popup') !== 'true' &&
			location.pathname.endsWith('/boka-bil') &&
			service !== SITE_TYPES.FLEX
		) {
			setShowBookingModal(true);
		} else if (
			isCustomerLoggedIn &&
			businessCustomer &&
			!isMissingInformationBusiness &&
			isMissingInformationBusiness !== null &&
			localStorage.getItem('hide-popup') !== 'true' &&
			location.pathname.endsWith('/boka-bil') &&
			service === SITE_TYPES.FLEX &&
			showFlexBusinessModal === 'true'
		) {
			setShowBookingModal(true);
		}
	}, [isCustomerLoggedIn, customerInfo.businessEntity, isMissingInformationBusiness, location.pathname]);
	return (
		<>
			{error && (
				<ErrorModal
					onClose={() => {
						doSetError();
						if (error.redirectOnClose) {
							navigate('/redirect?showLoginModal=true');
						} else if (typeof window !== 'undefined') {
							window.scrollTo(0, 0);
						}
					}}
					titleKey={error.titleKey}
					messageKey={error.messageKey}
					title={error.title}
				>
					{error.message}
				</ErrorModal>
			)}
			<BookingModal open={showBookingModal} handleClose={() => setShowBookingModal(false)} />
			<Chat embeddable={embeddable} isStationsPage={isStationsPage} siteType={siteType} />
			<nav {...bem('')}>
				<div {...bem('type-wrapper-desktop')}>
					<div style={{ display: 'flex' }}>
						{!isLandingPage && (
							<TypeLinks
								siteType={siteType}
								doSetSiteType={siteType => {
									doSetSiteType(siteType);
									doSetBookingSiteType(siteType);
								}}
								bemClass={'type-desktop'}
								language={language}
								service={service}
							/>
						)}
						<Languages languageArray={languageArray} location={location}></Languages>
					</div>
					<ProductsMenu location={location} items={landingLinks} language={language} />
				</div>
				<div {...bem('content', { expanded: menuExpanded })}>
					<div {...bem('branding')}>
						<Logo location={location} />
					</div>
					<div {...bem('nav-wrapper')}>
						<div {...bem('nav-actions')}>
							{!isLandingPage && (
								<Button
									aria-label={t('kinto.header.book-car')}
									color="primary"
									variant="contained"
									onClick={() => {
										handleBookingClick();
									}}
								>
									<span {...bem('mobile')}>
										<Location aria-hidden="true" />
										<p className="tiny">
											{service === SITE_TYPES.FLEX
												? t('kinto.header.flex.book-car')
												: t('kinto.header.book-car')}{' '}
										</p>
									</span>
									<span {...bem('desktop')}>
										{service === SITE_TYPES.FLEX
											? t('kinto.header.flex.book-car')
											: t('kinto.header.book-car')}
									</span>
								</Button>
							)}
							<Button
								color="primary"
								variant="outlined"
								aria-label={t('kinto.header.my-pages')}
								onClick={handleMyPagesClick}
							>
								<span {...bem('mobile')}>
									<User aria-hidden="true" />
									<p className="tiny">
										{isCustomerLoggedIn ? t('kinto.header.my-pages') : t('kinto.header.login')}
									</p>
								</span>
								<span {...bem('desktop')}>
									<p>{isCustomerLoggedIn ? t('kinto.header.my-pages') : t('kinto.header.login')}</p>
								</span>
							</Button>

							<LoginModal
								open={showModal}
								hideRegistration={hideRegistration}
								callback={callbackFn}
								handleClose={() => {
									setShowModal(false);
									if (closeCallbackFn) {
										closeCallbackFn();
										closeCallbackFn = null;
										callbackFn = () => null;
									}
								}}
								showRedirectInfoText={showRedirectInfoText}
								returnToBookingAfterRegistration={returnToBookingAfterRegistration}
								showTimeOutText={showTimeOutText}
							/>
							<BlockedUserInfoModal
								open={showAccountBlockedInformation}
								blockedTitle={t('kinto.blocked-user.title')}
								blockedText={t('kinto.blocked-user.text')}
								handleClose={() => {
									doSetShowAccountBlockedInformation(false);
									doSetShowMissingInformation(false);
									if (isCustomerLoggedIn) {
										doLogoutCustomer();
										navigate('/');
									}
								}}
							/>
							<BlockedUserInfoModal
								open={showAccountNotAllowedInformation}
								blockedTitle={t('kinto.blocked-user-onboarding.title')}
								blockedText={t('kinto.blocked-user.text')}
								handleClose={() => {
									doSetShowAccountBlockedInformation(false);
									doSetShowMissingInformation(false);
									if (isCustomerLoggedIn) {
										doLogoutCustomer();
										navigate('/');
									}
								}}
							/>
							<MissingInformationModal
								open={
									showMissingInformation &&
									!showAccountNotAllowedInformation &&
									!showAccountBlockedInformation
								}
								handleClose={() => doSetShowMissingInformation(false)}
							/>
						</div>
						<button
							type="button"
							aria-label={t('kinto.header.menu')}
							onClick={toggleMenu}
							{...bem('burger', { expanded: menuExpanded })}
						>
							<Menu aria-hidden="true" />
						</button>
						<div
							role="button"
							tabIndex={-1}
							{...bem('nav-menu-backdrop')}
							onClick={() => setMenuExpanded(false)}
							onKeyDown={() => setMenuExpanded(false)}
						/>
						<div {...bem('nav-menu')}>
							<div {...bem('nav-menu-top')}>
								<div>
									{languageMobileOpen ? (
										<button {...bem('back-button')} onClick={() => setLanguageMobileOpen(false)}>
											<NavigateBefore /> <p>{t('kinto.menu.back')}</p>
										</button>
									) : (
										languageArray.length > 1 && (
											<button onClick={() => setLanguageMobileOpen(true)}>
												<p {...bem('link', 'type-desktop', 'active mobile')}>
													{language.toUpperCase()}
													{' - '}
													{t('kinto.menu.change-language')}
												</p>
											</button>
										)
									)}
								</div>
								<button tabIndex={0} onClick={toggleMenu} {...bem('burger')}>
									<Close fontSize="large" />
								</button>
							</div>

							{languageMobileOpen ? (
								<div {...bem('language-mobile')}>
									<h3 {...bem('language-modal-header')}>{t(`kinto.choose-language`)}</h3>
									{languageArray.map(item => {
										return (
											<Link
												{...bem('language-modal-language')}
												to={getHomePageForLanguage(defaultLanguage, item, homePage)}
												onClick={() => {
													setMenuExpanded(false);
													doSetLanguage(item);
													dispatch(setLanguage(item));
													doSetService(SITE_TYPES.SHARE);
												}}
												onKeyDown={() => setMenuExpanded(false)}
												key={item}
											>
												<h3>{t(`kinto.choose-language.${item}`)}</h3>
											</Link>
										);
									})}
								</div>
							) : (
								<div {...bem('type-wrapper-mobile')}>
									<ProductsMenu
										location={location}
										items={landingLinks}
										language={language}
									></ProductsMenu>
									<div {...bem('type-wrapper-mobile-types')}>
										<TypeLinks
											doSetSiteType={siteType => {
												doSetSiteType(siteType);
												doSetBookingSiteType(siteType);
											}}
											bemClass={'type-mobile'}
											siteType={siteType}
											language={language}
											service={service}
										/>
									</div>
								</div>
							)}

							{!languageMobileOpen &&
								navigationLinks !== undefined &&
								navigationLinksSecond !== undefined && (
									<>
										<MainMenu items={navigationLinks} onItemClick={onMenuItemClick} />

										<div {...bem('main-menu-second')}>
											<ul>
												{navigationLinksSecond
													.filter(item => item.language === language)
													.map(({ url, label, id }) => (
														<li {...bem('menu-item')} key={id}>
															<Link
																to={url}
																onClick={() => setMenuExpanded(false)}
																{...bem('link-second')}
															>
																<p className="h4">{label}</p>
															</Link>
														</li>
													))}
											</ul>
										</div>
									</>
								)}
						</div>
					</div>
				</div>
				{warningBannerText && (
					<div {...bem('warning-banner')}>
						<ExclamationMark />
						<div>{t(warningBannerText)}</div>
					</div>
				)}
			</nav>
		</>
	);
};

export default Header;
