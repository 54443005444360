import { useStaticQuery, graphql } from 'gatsby';

const useSetting = () => {
	const data = useStaticQuery(graphql`
		query {
			allSettings {
				edges {
					node {
						key
						value
					}
				}
			}
		}
	`);

	function getSetting(key, fallbackValue) {
		if (!fallbackValue) {
			fallbackValue = null;
		}
		const setting = data.allSettings.edges.find(edge => {
			const { node } = edge;
			return node.key === key;
		});
		return setting ? setting.node.value : fallbackValue;
	}

	return getSetting;
};

export default useSetting;
