import { get } from 'lodash';
import { SITE_TYPES } from '@src/bundles/site';
import {
	getStationAvailableVehicleTypesApiCall,
	createScheduledRentalApiCall,
	getVehicleLeasingEntitiesForStation,
} from '@src/custom-api/kinto-api/client';

const getCommitmentFromName = name => {
	try {
		const matchedValue = name.match(/\(([^()]*)\)/g).map(function ($0) {
			return $0.substring(1, $0.length - 1);
		});
		return parseInt(matchedValue);
	} catch (error) {
		return undefined;
	}
};

const getDateConstraintsFromName = name => {
	try {
		const matchedValues = name.match(/\[.+?\]/g).map(function ($0) {
			return $0.substring(1, $0.length - 1);
		});
		var constraints = matchedValues.map(mVal => {
			const parts = mVal.split(' ');
			const year = parseInt(parts[1].substring(0, 4));
			const month = parseInt(parts[1].substring(4, 6)) - 1; // JavaScript counts months from 0 to 11
			const day = parseInt(parts[1].substring(6, 8));
			return {
				operator: parts[0],
				date: new Date(year, month, day),
			};
		});
		return constraints;
	} catch (error) {
		return undefined;
	}
};

const getNameWithoutCommitmentAndConstraint = name => {
	try {
		return name.replace(/ \([\s\S]*?\)/g, '').replace(/ \[[\s\S]*?\]/g, '');
	} catch (error) {
		return name;
	}
};

const booking = {
	name: 'booking',
	getReducer: () => {
		const initialState = {
			available_vehicles: [],
			selectedStation: null,
			previousSelectedStation: null,
			selectedCar: null,
			isSelectedCarAvailableInStation: null,
			startDate: null,
			startTime: null,
			endDate: null,
			endTime: null,
			loading: false,
			lastError: false,
			insurance: null,
			insuranceChecked: null,
			comment: null,
			serviceId: null,
			selectedFlexOption: null,
			vehicleLeasingEntities: null,
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_SELECTED_STATION':
					const oldState = { ...state };
					if (!payload) {
						return {
							...state,
							selectedStation: payload,
							previousSelectedStation: oldState.selectedStation,
						};
					}
					return {
						...state,
						selectedStation: payload,
					};
				case 'RESET_SELECTED_STATION':
					return {
						...state,
						selectedStation: null,
						previousSelectedStation: state.selectedStation,
						available_vehicles: [],
					};
				case 'SET_BOOKING_SELECTED_CAR':
					return {
						...state,
						selectedCar: payload,
						serviceId: payload.service_id,
					};
				case 'RESET_BOOKING_SELECTED_CAR':
					return { ...state, selectedCar: null, available_vehicles: [] };
				case 'RESET_BOOKING_INFO':
					return { ...initialState };
				case 'SET_START_DATE':
					return { ...state, startDate: payload };
				case 'SET_START_TIME':
					return { ...state, startTime: payload };
				case 'SET_END_DATE':
					return { ...state, endDate: payload };
				case 'SET_END_TIME':
					return { ...state, endTime: payload };
				case 'SET_INSURANCE':
					return { ...state, insurance: payload };
				case 'SET_INSURANCE_CHECKED':
					return { ...state, insuranceChecked: payload };
				case 'SET_COMMENT':
					return { ...state, comment: payload };
				case 'SET_SERVICE_ID':
					return { ...state, serviceId: payload };
				case 'SET_INTERVAL':
					return {
						...state,
						startDate: payload.startDate,
						startTime: payload.startTime,
						endDate: payload.endDate,
						endTime: payload.endTime,
					};
				case 'RESET_INTERVAL':
					return {
						...state,
						startDate: null,
						startTime: null,
						endDate: null,
						endTime: null,
					};
				case 'FETCH_AVAILABLE_VEHICLES_SUCCESS':
					return {
						...state,
						loading: false,
						lastError: null,
						available_vehicles: payload,
					};
				case 'FETCH_AVAILABLE_VEHICLES_STARTED':
					return { ...state, loading: true, lastError: null };
				case 'FETCH_AVAILABLE_VEHICLES_FAILED':
					return { ...state, loading: false, lastError: payload };
				case 'SCHEDULE_RENTAL_SUCCESS':
					return {
						...state,
						loading: false,
						lastError: false,
					};
				case 'SCHEDULE_RENTAL_STARTED':
					return { ...state, loading: true, lastError: null };
				case 'SCHEDULE_RENTAL_FAILED':
					return {
						...state,
						loading: false,
						lastError: payload,
						available_vehicles: [],
					};
				case 'SET_FLEX_SELECTED_OPTION':
					return { ...state, selectedFlexOption: payload };
				case 'SET_IS_SELECTED_CAR_AVAILABLE_IN_STATION':
					return { ...state, isSelectedCarAvailableInStation: payload };
				case 'SET_VEHICLE_LEASING_ENTITIES':
					return { ...state, vehicleLeasingEntities: payload };
				default:
					return state;
			}
		};
	},
	doSetBookingSelectedCar:
		payload =>
		({ dispatch }) => {
			dispatch({
				type: 'SET_IS_SELECTED_CAR_AVAILABLE_IN_STATION',
				payload: null,
			});
			dispatch({ type: 'SET_BOOKING_SELECTED_CAR', payload });
		},
	doResetBookingSelectedCar:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_BOOKING_SELECTED_CAR' });
		},
	doResetBookingInfo:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_BOOKING_INFO', payload: null });
		},
	doSetStartDate:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_START_DATE', payload });
		},
	doSetStartTime:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_START_TIME', payload });
		},
	doSetEndDate:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_END_DATE', payload });
		},
	doSetEndTime:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_END_TIME', payload });
		},
	doSetInsurance:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_INSURANCE', payload });
		},
	doSetInsuranceChecked:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_INSURANCE_CHECKED', payload });
		},
	doSetComment:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_COMMENT', payload });
		},
	doSetServiceId:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_SERVICE_ID', payload });
		},
	doSetInterval:
		(payload = null) =>
		async ({ dispatch, store }) => {
			dispatch({ type: 'SET_INTERVAL', payload });
			const { startTime, endTime } = payload;
			store.doFetchAvailableVehicles({ startTime, endTime });
		},
	doResetInterval:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_INTERVAL' });
		},
	doSetVehicleLeasingEntities:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_VEHICLE_LEASING_ENTITIES', payload });
		},
	doFetchAvailableVehicles:
		({ startTime = null, endTime = null } = {}) =>
		async ({ dispatch, getState, store }) => {
			dispatch({ type: 'FETCH_AVAILABLE_VEHICLES_STARTED' });
			const state = getState();
			const newStartTime = startTime ? startTime : state.booking.startTime;
			const newEndTime = endTime ? endTime : state.booking.endTime;
			const station = state.booking.selectedStation;

			if (newStartTime && newEndTime && station) {
				try {
					const isCustomerLoggedIn = !!state.customer.token;
					const bookingType = !isCustomerLoggedIn
						? 'PERSONAL'
						: state.site.bookingType === SITE_TYPES.BUSINESS
						? 'BUSINESS'
						: 'PERSONAL';
					const flexOption = state.booking.selectedFlexOption;
					const newEndTimeObj = new Date(newStartTime);
					const newStartTimeObj = new Date(newStartTime);
					if (flexOption) {
						newStartTimeObj.setUTCHours(12);

						const commit = flexOption.plan.billing_cycle_commitment;
						newEndTimeObj.setMonth(newEndTimeObj.getMonth() + commit);
						newEndTimeObj.setUTCHours(12);
					}
					const response = flexOption
						? await getStationAvailableVehicleTypesApiCall(
								isCustomerLoggedIn,
								station.id,
								newStartTimeObj.toISOString(),
								newEndTimeObj.toISOString(),
								bookingType,
								state.site.language
						  )
						: await getStationAvailableVehicleTypesApiCall(
								isCustomerLoggedIn,
								station.id,
								newStartTime,
								newEndTime,
								bookingType,
								state.site.language
						  );

					dispatch({
						type: 'FETCH_AVAILABLE_VEHICLES_SUCCESS',
						payload: response.data.vehicle_types,
					});

					const selectedCar = response.data.vehicle_types.find(
						car => car.id === state.booking.selectedCar.VehicleType
					);
					if (selectedCar) {
						dispatch({
							type: 'SET_IS_SELECTED_CAR_AVAILABLE_IN_STATION',
							payload: true,
						});
						store.doSetServiceId(selectedCar.service_id);
					} else {
						dispatch({
							type: 'SET_IS_SELECTED_CAR_AVAILABLE_IN_STATION',
							payload: false,
						});
					}
				} catch (error) {
					dispatch({ type: 'FETCH_AVAILABLE_VEHICLES_FAILED', payload: error });
				}
			}
		},
	doSelectStation:
		payload =>
		async ({ dispatch, getState }) => {
			const state = getState();
			dispatch({ type: 'SET_SELECTED_STATION', payload });
			const vehicleLeasingEntities =
				state.site.service === SITE_TYPES.FLEX && payload
					? await getVehicleLeasingEntitiesForStation(payload.id)
					: undefined;
			if (vehicleLeasingEntities && vehicleLeasingEntities.data) {
				vehicleLeasingEntities.data.leasing_entities.forEach(ent => {
					const planCommitment = getCommitmentFromName(ent.name);
					const dateConstraints = getDateConstraintsFromName(ent.name);
					ent.plan.date_constraints = dateConstraints;
					ent.plan.billing_cycle_commitment = planCommitment || 1;
					ent.name = getNameWithoutCommitmentAndConstraint(ent.name);
					if (planCommitment === 1) {
						ent.plan.isRecurrent = true;
						ent.plan.billing_cycle_commitment = 6; // 6 months recurrent plan
					}
				});
				dispatch({
					type: 'SET_VEHICLE_LEASING_ENTITIES',
					payload: vehicleLeasingEntities.data.leasing_entities,
				});
			}
		},
	doResetSelectedStation:
		() =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_SELECTED_STATION', payload: null });
		},
	//TODO: Replace this or the old one
	doScheduleRentalNew:
		(payload: {
			//TODO: Move to model!
			vehicleId: string;
			stationId: string;
			startTime: string;
			endTime: string;
			leasingEntityId: number;
			serviceId: string;
		}) =>
		async ({ dispatch, getState }) => {
			const state = getState();
			const isCustomerLoggedIn = !!state.customer.token;

			let bookingInformation = {
				customer_id: state.customer.customerId,
				vehicle_type_id: payload.vehicleId,
				start_station_id: payload.stationId,
				start_date_time: payload.startTime,
				end_date_time: payload.endTime,
				service_type: !isCustomerLoggedIn
					? 'PERSONAL'
					: state.site.bookingType === SITE_TYPES.BUSINESS
					? 'BUSINESS'
					: 'PERSONAL',
				addon_data: [
					{
						addon_id: state.booking.insurance.id, // TODO: check if always provided!
						is_terms_accepted: state.booking.insuranceChecked || false,
					},
				],
			} as any;

			if (state.booking.comment !== null && state.booking.comment !== '') {
				bookingInformation.rental_reason = state.booking.comment;
			}
			if (state.booking.selectedFlexOption) {
				bookingInformation.reservation_mode = 'DISPATCHER_WEB'; //TODO: check with ridecell if needed!
				bookingInformation.product_type = 'lease';
				bookingInformation.vehicle_type_leasing_entity_id = payload.leasingEntityId;
			}
			try {
				dispatch({ type: 'SCHEDULE_RENTAL_STARTED' });
				await createScheduledRentalApiCall(payload.serviceId, bookingInformation, state.site.language);
				dispatch({
					type: 'SCHEDULE_RENTAL_SUCCESS',
				});
			} catch (error) {
				dispatch({ type: 'SCHEDULE_RENTAL_FAILED', payload: error });
				throw error;
			}
		},
	doScheduleRental:
		() =>
		async ({ dispatch, getState }) => {
			const state = getState();
			const ridecellId = state.booking.selectedCar.RidecellID;
			const isCustomerLoggedIn = !!state.customer.token;
			let bookingInformation = {
				customer_id: state.customer.customerId,
				vehicle_type_id: ridecellId ? ridecellId : state.booking.selectedCar.id,
				start_station_id: state.booking.selectedStation.id,
				start_date_time: state.booking.startTime,
				end_date_time: state.booking.endTime,
				service_type: !isCustomerLoggedIn
					? 'PERSONAL'
					: state.site.bookingType === SITE_TYPES.BUSINESS
					? 'BUSINESS'
					: 'PERSONAL',
			} as any;
			if (state.booking.insurance.is_terms_condition_mandatory === false) {
				bookingInformation.addon_data = [
					{
						addon_id: state.booking.insurance.id,
						is_terms_accepted: false,
					},
				];
			} else if (state.booking.insurance.is_terms_condition_mandatory === true) {
				bookingInformation.addon_data = [
					{
						addon_id: state.booking.insurance.id,
						is_terms_accepted: state.booking.insuranceChecked,
					},
				];
			}
			if (state.booking.comment !== null && state.booking.comment !== '') {
				bookingInformation.rental_reason = state.booking.comment;
			}
			if (state.booking.selectedFlexOption) {
				bookingInformation.reservation_mode = 'DISPATCHER_WEB';
				bookingInformation.vehicle_type_leasing_entity_id = state.booking.selectedFlexOption.id;
				bookingInformation.product_type = 'lease';
			}
			try {
				dispatch({ type: 'SCHEDULE_RENTAL_STARTED' });
				await createScheduledRentalApiCall(state.booking.serviceId, bookingInformation, state.site.language);
				dispatch({
					type: 'SCHEDULE_RENTAL_SUCCESS',
				});
			} catch (error) {
				dispatch({ type: 'SCHEDULE_RENTAL_FAILED', payload: error });
				throw error;
			}
		},
	selectStartDate: state => state.booking.startDate,
	selectStartTime: state => state.booking.startTime,
	selectEndDate: state => state.booking.endDate,
	selectEndTime: state => state.booking.endTime,
	selectSelectedStation: state => {
		return state.booking.selectedStation;
	},
	selectPreviousSelectedStation: state => state.booking.previousSelectedStation,
	selectAvailableCars: state => state.booking.available_vehicles,
	selectBookingInfo: state => {
		return {
			...state.booking,
		};
	},
	selectIsCarSelected: state => state.booking.selectedCar !== null,
	selectSelectedCar: state => {
		const selectedRidecellId = get(state, 'booking.selectedCar.RidecellID');
		const car = state.booking.available_vehicles?.find(vehicle_type => vehicle_type.id === selectedRidecellId);

		return {
			...state.booking.selectedCar,
			...car,
		};
	},
	selectIsSelectedCarAvailableInStation: state => {
		return state.booking.isSelectedCarAvailableInStation;
	},
	selectBookingLoading: state => state.booking.loading,
	selectFlexSelectedOption: state => state.booking.selectedFlexOption,
	doSetFlexSelectedOption:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_FLEX_SELECTED_OPTION', payload });
		},
	selectVehicleLeasingEntitiesForSelectedCar: state => {
		if (state.booking.selectedCar && state.booking.vehicleLeasingEntities) {
			const vehicleEntities = state.booking.vehicleLeasingEntities.filter(
				op => op.vehicle_type.id == state.booking.selectedCar.RidecellID
			);
			return vehicleEntities;
		}
	},
	selectAllVehicleLeasingEntities: state => {
		return state.booking.vehicleLeasingEntities;
	},
	selectHasVehicleLeasingEntitiesForSelectedCar: state => {
		if (state.booking.selectedCar && state.booking.vehicleLeasingEntities) {
			const vehicleEntities = state.booking.vehicleLeasingEntities.filter(
				op => op.vehicle_type.id == state.booking.selectedCar.RidecellID
			);
			return vehicleEntities && vehicleEntities.length > 0;
		}
		return false;
	},
};

export default booking;
