import { PaletteColor, PaletteColorOptions, Theme, ThemeOptions } from '@mui/material';
import * as variables from '@src/styles/1__settings/_vars.module.scss';

const smallTablet = `@media (min-width: ${variables.breakpointSm})`;
const tablet = `@media (min-width: ${variables.breakpointMd})`;
const desktop = `@media (min-width: ${variables.breakpointLg})`;

export const themeCustom: ThemeOptions = {
	//TODO: Bug fixed with parseInt but the breakpoints are from different perspective (min-width) and MUI is using a fixed size for each breakpoint
	// breakpoints: {
	// 	values: {
	// 		xs: Number.parseInt(variables.breakpointXs),
	// 		sm: Number.parseInt(variables.breakpointSm),
	// 		md: Number.parseInt(variables.breakpointMd),
	// 		lg: Number.parseInt(variables.breakpointLg),
	// 		xl: Number.parseInt(variables.breakpointXl),
	// 	},
	// },
	palette: {
		common: {
			black: variables.colorBlack,
			white: variables.colorWhite,
		},
		primary: {
			light: variables.colorPrimaryLight,
			main: variables.colorPrimary,
			dark: variables.colorPrimaryDark,
		},
		secondary: {
			main: variables.colorWhite,
		},
		error: {
			main: variables.colorError,
		},
		info: {
			main: variables.colorInfo,
		},
		focus: {
			main: variables.colorTertiary,
			dark: variables.colorTertiaryDark,
			contrastText: variables.colorBlack,
		},
		success: {
			main: variables.colorSuccess,
		},
		text: {
			primary: variables.colorBlack,
		},
		popover: {
			main: variables.colorWhite,
		},
	},
	typography: {
		fontFamily: '"Toyota Type Book", "Arial", sans-serif',
		button: {
			fontSize: '1rem',
			textTransform: variables.textReset,
		},
	},

	//Overrides components
	components: {
		//Container override
		MuiContainer: {
			styleOverrides: {
				root: {
					margin: 'auto',

					[smallTablet]: {
						paddingRight: '1.5rem',
						paddingLeft: '1.5rem',
					},
					[tablet]: {
						paddingRight: '1.25rem',
						paddingLeft: '1.25rem',
					},
					[desktop]: {
						maxWidth: '68.25rem',
					},
				},
				maxWidthMd: {
					[desktop]: {
						maxWidth: 'none',
						padding: 0,
					},
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: { color: variables.colorPrimary },
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 'bold',
				},
				contained: {
					paddingTop: '10px !important',
					paddingBottom: '10px !important',
					'&$disabled': {
						backgroundColor: variables.colorBackgroundDark,
						color: variables.colorTextDisabled,
					},
				},
				containedPrimary: {
					backgroundColor: variables.colorPrimaryDark,
					'&:hover': {
						backgroundColor: variables.colorPrimaryXDark,
					},
				},
				containedSecondary: {
					backgroundColor: variables.colorWhite,
					color: variables.colorPrimaryDark,
					boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
					'&:hover': {
						backgroundColor: variables.colorBackground,
						color: variables.colorPrimaryXDark,
						boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
					},
				},
				outlinedPrimary: {
					backgroundColor: variables.colorWhite,
					color: variables.colorPrimaryDark,
					border: `1px solid ${variables.colorPrimaryDark}`,
					'&:hover': {
						color: variables.colorPrimaryXDark,
						backgroundColor: variables.colorBackground,
						border: `1px solid ${variables.colorPrimaryXDark}`,
					},
					'&$disabled': {
						borderColor: variables.colorBackgroundDark,
						color: variables.colorTextDisabled,
					},
				},
				outlined: {
					paddingTop: '11px !important',
					paddingBottom: '11px !important',
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					border: `1px solid ${variables.colorBorder}`,
					borderBottomWidth: 0,
					backgroundColor: 'rgba(0, 0, 0, 0)',
					boxShadow: 'none',
					'&:last-child': {
						borderBottom: `1px solid ${variables.colorBorder}`,
					},
					'&:before': {
						display: 'none',
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					padding: '0 1rem',
					fontWeight: 'bold',
				},
				content: {
					margin: '1rem 0',
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: '.5rem 1rem 1rem',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					marginBottom: '.5rem',
					border: 'solid 1px #e3e9eb',
				},
				elevation1: {
					boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '1.5rem 1.25rem .5rem 1.25rem',
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: '.5rem 1.25rem 1.25rem',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					height: '24px',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					padding: '11px 15px 15px !important',
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				shrink: {
					transform: 'translate(14px, -10px) scale(0.75) !important',
				},
				outlined: {
					transform: 'translate(14px, 13px) scale(1)',
				},
			},
		},
	},
};

//Module declaration overrides
declare module '@mui/material/styles' {
	interface Palette {
		focus: PaletteColor;
		popover: PaletteColor;
	}

	interface PaletteOptions {
		focus?: PaletteColorOptions;
		popover?: PaletteColorOptions;
	}
}
declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		focus: true;
	}
}
